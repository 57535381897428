import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Loadable from 'react-loadable';

import Button from '../components/Button';
import Helmet from '../components/Helmet';
import Layout from '../components/GatsbyLayout';
import Subtitle from '../components/Subsection/Subtitle';

import { isBottom, isTop } from '../util/scrollbar';
import isTouchDevice from '../util/device';
import Article from '../components/Article';

const Loading = () => <h3>Loading...</h3>;

const SocialShareComponent = Loadable({
  loader: () => import('../components/SocialShare'),
  loading: Loading
});

class BlogPostTemplate extends Component {
  static renderAuthor(author, isSecond) {
    const authorSection = `Author - ${author.id}`;

    return (
      <div className="main__section__content post__author-container">
        <Img
          className="post__author-avatar"
          fluid={author.avatar.childImageSharp.fluid}
          alt={`Author - ${author.id}`}
          title={`Author - ${author.id}`}
        />

        <div className="post__author">
          <div>
            <p className="post__author-name" tabIndex="0">{authorSection}</p>
            <p className="post__author-bio" tabIndex="0">{author.position}</p>
          </div>

          {!isSecond && (
            <div className="post__right-side-avatar">
              <p className="post__question-txt" tabIndex="0">Got a question?</p>
              <Button type="primary-large-light" goTo="/contact/" title="Ask Our Experts" internalLink />
            </div>
          )}
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    const { data: { markdownRemark: post, allMarkdownRemark: { edges } } } = props;

    const latestPosts = edges
      .filter(edge => !!edge.node.frontmatter.date && edge.node.id !== post.id)
      .map(edge => (
        <Article
          key={edge.node.id}
          title={edge.node.frontmatter.title}
          readingTime={edge.node.timeToRead}
          date={edge.node.frontmatter.date}
          path={edge.node.frontmatter.path}
          imageFluid={edge.node.frontmatter.mainImage.childImageSharp.fluid}
        />
      )).slice(0, 3);

    this.state = {
      latestPosts,
      stickShare: {
        enabled: false,
        top: 0,
      },
      ticking: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if ((location.state || {}).prevPath === '/blog/') {
      localStorage.setItem('blog', 'true');
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { ticking } = this.state;

    if (!ticking) {
      this.setState({ ticking: true });

      requestAnimationFrame(() => this.updateSharePosition());
    }
  };

  enableStickShare() {
    const { stickShare: { top } } = this.state;

    this.setState({
      stickShare: {
        enabled: true,
        // In order stick the social section vertically we'll use its initial distance to top
        top: !top ? document.getElementById('article-social-share').getBoundingClientRect().top : top
      }
    });
  }

  disableStickShare() {
    const { stickShare } = this.state;

    this.setState({
      stickShare: {
        ...stickShare,
        enabled: false
      }
    });
  }

  updateSharePosition() {
    const articleShare = document.getElementById('article-social-share');
    const articleContent = document.getElementById('article-post-content');
    const latestArticles = document.getElementById('article-latest');

    const arrivedToTop = articleContent ? articleContent.getBoundingClientRect().top > window.scrollY : false;
    const arrivedToContent = isBottom(articleShare);
    const arrivedToBottom = isTop(latestArticles);

    if (arrivedToTop) {
      this.disableStickShare();
    } else if (arrivedToContent && !arrivedToBottom) {
      this.enableStickShare();
    } else if (arrivedToBottom) {
      this.disableStickShare();
    }

    this.setState({ ticking: false });
  }

  renderSocialShare(title) {
    const { location: { href } } = this.props;
    const { stickShare: { enabled, top } } = this.state;

    const shareClass = `post__share ${enabled ? 'post__share--fixed' : ''}`;
    const applyMarginTop = enabled && !isTouchDevice();

    return (
      <div
        id="article-social-share"
        className={shareClass}
        style={{ ...applyMarginTop ? { top } : 0 }}
      >
        <span className="post__share-title">Share</span>

        <SocialShareComponent
          socialType="Facebook"
          quote={title}
          url={href}
        />

        <SocialShareComponent
          socialType="Twitter"
          quote={title}
          url={href}
        />

        <SocialShareComponent
          socialType="Linkedin"
          quote={title}
          url={href}
        />
      </div>
    );
  }

  renderHelmet(seo, author) {
    const { data: { markdownRemark: {
      frontmatter: { title, mainImage }
    } } } = this.props;

    const { id, description, keywords } = seo;

    return (
      <Helmet
        title={`${title} | MCRO`}
        description={`${description}`}
        keywords={`${keywords}`}
        author={author && author.id}
        thumbnail={mainImage.childImageSharp.sizes.src}
        canonical
        canonicalPage={`blog/${id}`}
      />
    );
  }

  renderLatestArticles() {
    const { latestPosts } = this.state;

    return (
      <div id="article-latest" className="post__latest-section">
        <Subtitle
          title="Featured Articles"
          withoutLine
          titleType="withDot"
        />

        <div className="main__section__content post__latest-items">
          <div className="articles">
            {latestPosts}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data: { markdownRemark: post }, location } = this.props;
    const { timeToRead, frontmatter: { title, seo, author, authorSecond, date } } = post;
    return (
      <Layout location={location}>
        {this.renderHelmet(seo, author)}

        <div className="main post">
          <Img fluid={seo.banner.childImageSharp.fluid} alt={title} title={title} />

          <div className="main__inner">
            <div className="main__section post__section">
              <div id="article-post-content" className="post__content">
                <Subtitle
                  title={title}
                  classNames="text-uppercase"
                  withoutLine
                  titleType="h1"
                  blogTitle
                />
                <div className="post-link__info-text blog-subtitle font-title2">
                  <div className="blog-subtitle__font" tabIndex="0">
                    <i className="fa fa-calendar" />
                    {`${date}`}
                  </div>
                  <div className="blog-subtitle__font" tabIndex="0">
                    <i className="fa fa-clock-o" />
                    {`${timeToRead} min Read`}
                  </div>
                </div>

                <div className="main__section__content" tabIndex="0" dangerouslySetInnerHTML={{ __html: post.html }} />

                {this.renderSocialShare(title)}

                {author && BlogPostTemplate.renderAuthor(author)}
                {authorSecond && BlogPostTemplate.renderAuthor(authorSecond, true)}
              </div>
            </div>
          </div>

          {this.renderLatestArticles()}
        </div>
      </Layout>
    );
  }
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
         seo {
           id
           description
           keywords
           banner {
            childImageSharp {
              fluid(maxWidth: 1440)  {
                ...GatsbyImageSharpFluid
              }
            }
         }
        }
        mainImage {
            childImageSharp{
                sizes(maxWidth: 500) {
                    ...GatsbyImageSharpSizes
                }
            }
        }
        author {
          id
          position
          avatar {
              childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
              }
          }
        }
        authorSecond {
          id
          position
          avatar {
              childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
              }
          }
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              timeToRead
              frontmatter {
                title
                date(formatString: "MMM DD, YYYY")
                path
                 mainImage {
                    childImageSharp{
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                        sizes(maxWidth: 500) {
                            ...GatsbyImageSharpSizes
                        }
                    }
                }
              }
            }
          }
        }
  }
`;
