import React from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import Img from 'gatsby-image';

import calendar from '../assets/images/svg/calendar-regular.svg';
import clock from '../assets/images/svg/clock-regular.svg';

const Article = ({ title, readingTime, date, path, description, imageFluid, imageFluidMobile }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 414px)'
  });

  const articleImage = isMobile && imageFluidMobile ? imageFluidMobile : imageFluid;

  return (
    <div className="article">
      <div className="article__image">
        <Link to={path} tabIndex="-1">
          {imageFluid && articleImage
            ? <Img className="article__image" fluid={articleImage} alt={title} title={title} width="375" height="250" />
            : <img className="article__image" src={articleImage} alt={title} title={title} width="375" height="250" />}
        </Link>
      </div>
      <div className="article__title">
        <Link to={path}>{title}</Link>
      </div>
      { description ? <div className="article__info" tabIndex="0">{description}</div>
        : (
          <div className="article__info">
            <div className="article__info--date" tabIndex="0">
              <img src={calendar} alt="calendar" title="calendar" width="14" height="16" />
              {date}
            </div>
            <div className="article__info--time" tabIndex="0">
              <img src={clock} alt="clock" title="clock" width="15.5" height="15.5" />
              {`${readingTime} min Read`}
            </div>
          </div>
        )}
    </div>
  );
};

export default Article;
